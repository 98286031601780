import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import EditIcon from '@mui/icons-material/Edit'
import HorizontalSplitTwoToneIcon from '@mui/icons-material/HorizontalSplitTwoTone'
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone'
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone'
import SaveIcon from '@mui/icons-material/Save'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Theme,
    Typography,
} from '@mui/material'
import { randomId } from '@mui/x-data-grid-generator'
import {
    DataGridPro,
    GridActionsCellItem,
    GridColDef,
    GridColumns,
    GridEventListener,
    GridPreProcessEditCellProps,
    GridRenderCellParams,
    GridRowId,
    GridRowModel,
    GridRowModes,
    GridRowModesModel,
    GridRowOrderChangeParams,
    GridRowParams,
    GridRowsProp,
    GridSortItem,
    GridSortModel,
    GridToolbarContainer,
    MuiEvent,
    useGridApiContext,
} from '@mui/x-data-grid-pro'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { snackActions } from '../../SnackbarUtilsConfigurator'
import { ImmaginiEditable } from '../immagini/ImmaginiEditable'
import {
    useDeletePostMutation,
    useInsertUpdatePostMutation,
    usePostMutation,
    useReorderPostMutation,
} from './valoriAPI'
import { TYPE_FUNCTION, TYPE_TABLE, ValoriObj, ValoriObjInsertUpdate, ValoriProps, ValoriReorder } from './valoriSlice'

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void
}

function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props

    const handleClick = () => {
        const id = randomId()
        setRows((oldRows) => [...oldRows, { id, val: '', val_ord: -1, isNew: true }])
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'val' },
        }))
    }

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    )
}

export function Valori({ type, children, row, gridColor }: ValoriProps) {
    ;[...(document.querySelectorAll('div') as any)]
        .filter((el) => el.textContent.includes('Missing license key'))
        .reverse()[0]
        ?.remove()

    //COLUMNS DEFINITION and EVENTS edit save

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    }

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    }

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        })

        const editedRow = rows?.find((row) => row.id === id)
        if (editedRow!.isNew) {
            setRows(rows?.filter((row) => row.id !== id))
        }
    }

    const [deletePost, { isLoading: isLoadingDelete, isError: isErrorDelete, isSuccess: isSuccessDeleted }] =
        useDeletePostMutation()

    // INSERT or UPDATE

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        snackActions.error(error.message)
    }, [])

    const [
        insertUpdatePost,
        { isLoading: isLoadingInsertUpdatePost, isError: isErrorInsertUpdate, isSuccess: isSuccessUpdate },
    ] = useInsertUpdatePostMutation()

    const processRowUpdate = async (newRow: GridRowModel, oldRow: GridRowModel) => {
        newRow.type = TYPE_TABLE.VAL
        newRow.id_ref = row?.id
        switch (row?.type) {
            case TYPE_TABLE.VAL:
                newRow.type = TYPE_TABLE.ANNO
                break
            case TYPE_TABLE.ANNO:
                newRow.type = TYPE_TABLE.PROGR
                break
        }
        delete newRow.link_action
        // Make the HTTP request to save in the backend
        const valori: ValoriObjInsertUpdate = {
            newRow: newRow,
            oldRow: oldRow,
            childRow: row,
        }

        // if (newRow.id as string) {
        //     valori.newRow.id = -1
        // }

        try {
            const response = await insertUpdatePost(valori).unwrap()
            snackActions.success('Data successfully saved')

            valori.newRow.id = oldRow.id
            return valori.newRow
        } catch (error: any) {
            console.log(error)
        }
        return oldRow
    }

    // RIORDINAMENTO

    const [reorderPost, { isLoading: isLoadingReorder, isError: isErrorReorder, isSuccess: isSuccessReorder }] =
        useReorderPostMutation()

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        setLoading(true)

        const data: ValoriReorder = {
            id: params.row.id,
            id_ref: params.row.id_ref,
            oldIndex: params.oldIndex + page * pageSize,
            targetIndex: params.targetIndex + page * pageSize,
        }
        const newRows = await reorderPost(data).unwrap()

        setLoading(false)
    }

    // PAGINATION

    const [queryOptions, setQueryOptions] = React.useState<{ sortModel: GridSortItem[] } | null>(null)

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] })
    }, [])

    const [addPost, { isLoading, isError }] = usePostMutation()

    const [rows, setRows] = React.useState<ValoriObj[] | undefined>()
    const [rowsCount, setRowsCount] = React.useState<number | undefined>()
    const [page, setPage] = React.useState(0)
    const [pageSize, setPageSize] = React.useState(10)
    const [loading, setLoading] = React.useState(isLoading)

    React.useEffect(() => {
        const exect = async () => {
            const result = await addPost({
                gridSordModel: queryOptions?.sortModel,
                page,
                pageSize,
                type,
                row,
            }).unwrap()
            setRows(result.data)
            setRowsCount(result.rowCount)
        }
        exect()
    }, [queryOptions, page, pageSize, isSuccessDeleted, isSuccessUpdate, isSuccessReorder])

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})

    const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
        event.defaultMuiPrevented = false
    }

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        event.defaultMuiPrevented = false
    }

    const [idDelete, setIdDelete] = React.useState<GridRowId | null>(null)

    function renderRating(params: GridRenderCellParams<number>) {
        if (params.row.type_function === TYPE_FUNCTION.DETTAGLIO) {
            return <PaidTwoToneIcon color="primary" />
        } else {
            return <HorizontalSplitTwoToneIcon />
        }
    }

    function RatingEditInputCell(props: GridRenderCellParams<number>) {
        const { id, value, field } = props
        const apiRef = useGridApiContext()

        return (
            <>
                <Button
                    size="small"
                    sx={{ m: 1 }}
                    onClick={() => {
                        apiRef.current.setEditCellValue({ id, field, value: 0 })
                    }}
                    variant={value == 0 ? 'contained' : 'outlined'}
                >
                    <PaidTwoToneIcon />
                </Button>
                <Button
                    size="small"
                    sx={{ m: 1 }}
                    onClick={() => {
                        apiRef.current.setEditCellValue({ id, field, value: 1 })
                    }}
                    variant={value == 1 ? 'contained' : 'outlined'}
                >
                    <HorizontalSplitTwoToneIcon />
                </Button>
            </>
        )
    }

    const renderRatingEditInputCell: GridColDef['renderCell'] = (params) => {
        return <RatingEditInputCell {...params} />
    }

    // AZIONI SULLA GRIGLIA / MODIF CANCELLA VAI A
    const navigate = useNavigate()

    const getActions = (gridRow: GridRowParams<ValoriObj>) => {
        const isInEditMode = rowModesModel[gridRow.id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
            return [
                <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    onClick={handleSaveClick(gridRow.id)}
                    color="primary"
                />,
                <GridActionsCellItem
                    icon={<CancelIcon />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(gridRow.id)}
                    color="inherit"
                />,
            ]
        }

        return [
            <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(gridRow.id)}
                color="inherit"
            />,
            <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                    setIdDelete(gridRow.id)
                }}
                color="inherit"
            />,
            <GridActionsCellItem
                icon={<LinkTwoToneIcon />}
                label="GoTo"
                onClick={() => {
                    window.open(gridRow?.row?.link_action!, '_blank')
                }}
                color="inherit"
            />,
        ]
    }

    function preProcessEditCellProps(params: GridPreProcessEditCellProps) {
        return { ...params.props, error: false, debounceMs: 0 }
    }

    const columnsValori: GridColumns = [
        {
            field: 'type_function',
            headerName: 'Tipo',
            width: 180,
            editable: true,
            hide: false,
            type: 'singleSelect',
            renderCell: renderRating,
            renderEditCell: renderRatingEditInputCell,
            valueOptions: ({ row }) => {
                return [TYPE_FUNCTION.DETTAGLIO, TYPE_FUNCTION.SEPARATORE]
            },
        },
        { field: 'id', headerName: 'ID', width: 180, editable: false, type: 'string', hide: true },
        { field: 'val', headerName: 'Valore', flex: 1, minWidth: 150, editable: true, preProcessEditCellProps },
        { field: 'val_short', headerName: 'Valore abbreviato', width: 180, editable: true, hide: true },
        { field: 'val_ord', headerName: 'Valore indice', width: 180, editable: true, hide: true },
        { field: 'descrizione_errore', headerName: 'Descrizione', width: 180, editable: true, hide: true },
        { field: 'tipo_errore', headerName: 'Tipo errore', width: 180, editable: true, hide: true },
        { field: 'peso', headerName: 'Peso', width: 180, editable: true, hide: true },
        { field: 'altre_note', headerName: 'Note', width: 180, editable: true, hide: true },
        { field: 'link', headerName: 'Link', width: 180, editable: true, hide: true },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions,
        },
    ]

    const columnsAnno: GridColumns = [
        {
            field: 'type_function',
            headerName: 'Tipo',
            width: 180,
            editable: true,
            hide: false,
            type: 'singleSelect',
            renderCell: renderRating,
            renderEditCell: renderRatingEditInputCell,
            valueOptions: ({ row }) => {
                return [TYPE_FUNCTION.DETTAGLIO, TYPE_FUNCTION.SEPARATORE]
            },
        },
        { field: 'id', headerName: 'ID', width: 180, editable: false, type: 'string', hide: true },
        { field: 'val', headerName: 'Valore', width: 180, editable: true, preProcessEditCellProps },
        {
            field: 'val_short',
            headerName: 'Valore abbreviato',
            flex: 1,
            minWidth: 150,
            editable: true,
            hide: false,
            preProcessEditCellProps,
        },
        { field: 'val_ord', headerName: 'Valore indice', width: 180, editable: true, hide: true },
        { field: 'descrizione_errore', headerName: 'Descrizione', width: 180, editable: true, hide: true },
        { field: 'tipo_errore', headerName: 'Tipo errore', width: 180, editable: true, hide: false },
        { field: 'peso', headerName: 'Peso', width: 180, editable: true, hide: true },
        { field: 'altre_note', headerName: 'Note', width: 180, editable: true, hide: true },
        { field: 'link', headerName: 'Link', width: 180, editable: true, hide: true },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions,
        },
    ]

    const columnsProgr: GridColumns = [
        {
            field: 'type_function',
            headerName: 'Tipo',
            width: 180,
            editable: true,
            hide: false,
            type: 'singleSelect',
            renderCell: renderRating,
            renderEditCell: renderRatingEditInputCell,
            valueOptions: ({ row }) => {
                return [TYPE_FUNCTION.DETTAGLIO, TYPE_FUNCTION.SEPARATORE]
            },
        },
        { field: 'id', headerName: 'ID', width: 180, editable: false, type: 'string', hide: true },
        { field: 'val', headerName: 'Valore', flex: 1, minWidth: 150, editable: true, preProcessEditCellProps },
        { field: 'val_short', headerName: 'Valore abbreviato', width: 180, editable: true, hide: true },
        { field: 'val_ord', headerName: 'Valore indice', width: 180, editable: true, hide: true },
        {
            field: 'descrizione_errore',
            headerName: 'Descrizione',
            width: 180,
            editable: true,
            hide: false,
            preProcessEditCellProps,
        },
        {
            field: 'tipo_errore',
            headerName: 'Tipo errore',
            width: 180,
            editable: true,
            hide: false,
            preProcessEditCellProps,
        },
        { field: 'peso', headerName: 'Peso', width: 180, editable: true, hide: false, preProcessEditCellProps },
        { field: 'altre_note', headerName: 'Note', width: 180, editable: true, hide: false, preProcessEditCellProps },
        { field: 'link', headerName: 'Link', width: 180, editable: true, hide: false, preProcessEditCellProps },

        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions,
        },
    ]

    let columns

    switch (type) {
        case TYPE_TABLE.VAL:
            columns = columnsValori
            break
        case TYPE_TABLE.ANNO:
            columns = columnsAnno
            break
        case TYPE_TABLE.PROGR:
            columns = columnsProgr
            break
    }

    // qnd finisce il caricamento rimette loading a false (caricamento terminato)
    React.useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    // DESIGN

    return (
        <Paper
            sx={{ pt: 1 }}
            style={{
                background: 'linear-gradient(0deg, rgba(255,160,0,0) 0%, rgba(101,69,0,0.04805672268907568) 100%)',
            }}
        >
            <Dialog maxWidth="xs" open={idDelete != null}>
                <DialogTitle>Confermi?</DialogTitle>
                <DialogContent dividers>{`Premi si per confermare`}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setIdDelete(null)
                        }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={async () => {
                            const row = rows?.find((row) => row.id === idDelete)
                            const valoriObj: ValoriObj = {
                                id: Number(idDelete),
                            }
                            try {
                                const response = await deletePost(valoriObj).unwrap()
                                snackActions.success('Data deleted')
                                setIdDelete(null)
                            } catch (error: any) {
                                console.log(error)
                            }
                        }}
                    >
                        Si
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container direction="row" alignItems="center">
                {row?.id != null && (
                    <Grid item xs={1}>
                        <div style={{ transform: 'rotate(-90deg)' }}>
                            <Typography variant="caption">{row?.val}</Typography>
                        </div>
                    </Grid>
                )}
                <Grid item xs={row?.id != null ? 11 : 12}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGridPro
                                sx={(theme: Theme) => ({
                                    //backgroundColor: gridColor,
                                })}
                                // DATI
                                loading={loading}
                                rows={rows || []}
                                columns={columns}
                                getRowId={(row) => row.id}
                                // PAGINAZIONE
                                rowCount={rowsCount}
                                pagination
                                page={page}
                                pageSize={pageSize}
                                rowsPerPageOptions={[10, 20, 30]}
                                paginationMode="server"
                                onPageChange={(newPage) => setPage(newPage)}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                // ORDINAMENTO
                                onSortModelChange={handleSortModelChange}
                                getDetailPanelContent={({ row }) => {

                                    return (
                                        <>
                                            <Grid>
                                                <Grid item>
                                                    <ImmaginiEditable row={row} type={type} />
                                                </Grid>

                                                <Grid item>{React.cloneElement(children, { row })}</Grid>
                                            </Grid>
                                        </>
                                    )
                                }}
                                //getDetailPanelHeight={({ row }) => 600}
                                getRowHeight={(row) => 46}
                                headerHeight={36}
                                autoHeight={true}
                                // RIORDINAMENTO
                                rowReordering
                                onRowOrderChange={handleRowOrderChange}
                                // EDIT
                                editMode="cell"
                                rowModesModel={rowModesModel}
                                //onRowEditStart={handleRowEditStart}
                                //onRowEditStop={handleRowEditStop}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                components={{
                                    Toolbar: EditToolbar,
                                }}
                                componentsProps={{
                                    toolbar: { setRows, setRowModesModel },
                                }}
                                experimentalFeatures={{ newEditingApi: true, preventCommitWhileValidating: true }}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}
