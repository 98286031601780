import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Divider,
    Grid,
    Link,
    Paper,
    TextField,
    Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { SyntheticEvent, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LabelDetail from '../../components/LabelDetail'
import { Breadcrums } from '../breadcrumbs/Breadcrums'
import { Immagini } from '../immagini/Immagini'
import { ImmaginiSingola } from '../immagini/ImmaginiSingola'
import { TYPE_FUNCTION, TYPE_TABLE } from '../_valori/valoriSlice'
import { useDettaglioQuery } from './groupValoriAPI'
import { ValoriObj } from './groupValoriSlice'

const changeToLabel = (type: TYPE_TABLE) => {
    switch (type) {
        case TYPE_TABLE.VAL:
            return 'Valore'
        case TYPE_TABLE.ANNO:
            return 'Anno'
        case TYPE_TABLE.PROGR:
            return 'Progressivo'
    }
}

export function GroupValori() {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const arrParam = pathname.replace('/', '').split('.')

    const { data } = useDettaglioQuery({
        val: arrParam.length > 0 ? arrParam[0] : '',
        anno_short: arrParam.length > 1 ? arrParam[1] : '',
        progr: arrParam.length > 2 ? arrParam[2] : '',
    })

    return (
        <Paper
            elevation={10}
            sx={{ pl: 3, pr: 3, pb: 2, pt: 3, mb: 4 }}
            style={{
                background: 'linear-gradient(0deg, rgba(255,160,0,0) 0%, rgba(101,69,0,0.04805672268907568) 100%)',
            }}
        >
            <Typography align="right" variant="h5">
                Catalogo errori di coniazione
            </Typography>
            <hr />
            <Breadcrums />
            {data?.data.length === 0 ? (
                <Typography>Nessun dettaglio trovato</Typography>
            ) : (
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {data?.data.map((it) => {
                        if (it.type_function === TYPE_FUNCTION.SEPARATORE) {
                            return (
                                <Grid item xs={12} key={it.val}>
                                    <Grid
                                        spacing={0}
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        justifyItems="center"
                                    >
                                        <Grid item xs={3} sm={1}>
                                            <ImmaginiSingola
                                                valoriObj={it}
                                                onClick={() => {
                                                    navigate('/' + it.link_action!)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <LabelDetail label={''} descrizione={it.val} />
                                        </Grid>
                                        <Grid item xs={12} sm={7}>
                                            <Divider light />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }

                        const val_short =
                            it.val_short && it.val_short?.length > 0 ? '('.concat(it.val_short).concat(')') : ''
                        return (
                            <Grid item xs={12} sm={4} md={4} key={it.val}>
                                <Card>
                                    {it.type !== TYPE_TABLE.VAL && (
                                        <CardActionArea
                                            onClick={() => {
                                                navigate('/' + it.link_action!)
                                            }}
                                        >
                                            <CardContent>
                                                <LabelDetail
                                                    label={changeToLabel(it.type!) + ' ' + val_short}
                                                    descrizione={it.val}
                                                />
                                                <LabelDetail
                                                    label={'Tipo errore'}
                                                    descrizione={it.tipo_errore}
                                                />
                                            </CardContent>
                                        </CardActionArea>
                                    )}
                                    <Immagini
                                            valoriObj={it}
                                            onClick={() => {
                                                navigate('/' + it.link_action!)
                                            }}
                                        />
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            )}
        </Paper>
    )
}
