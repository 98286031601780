import { ThemeProvider } from '@emotion/react'
import Brightness4TwoToneIcon from '@mui/icons-material/Brightness4TwoTone'
import { Box, createTheme, CssBaseline, FormControlLabel, Switch, ThemeOptions } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Footer } from './components/Footer'
import { Counter } from './features/counter/Counter'
import { Login } from './features/login/Login'
import PrivateRoute from './features/login/PrivateRoute'
import { Ricerca } from './features/ricerca/Ricerca'
import { TopTen } from './features/topten/TopTen'
import { Dettaglio } from './features/_dettaglio/Dettaglio'
import { GroupValori } from './features/_groupValori/GroupValori'
import { Valori } from './features/_valori/Valori'
import { TYPE_TABLE } from './features/_valori/valoriSlice'
import ResponsiveDrawer from './ResponsiveDrawer'

// Define theme settings
const light: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#4D4D4D',
        },
        secondary: {
            main: '#EA514A',
        },
    },
    shape: {
        borderRadius: 4,
    },
}

const dark: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#4D4D4D',
        },
        secondary: {
            main: '#EA514A',
        },
    },
    shape: {
        borderRadius: 4,
    },
}

function App() {
    // The light theme is used by default
    const [isDarkTheme, setIsDarkTheme] = useState(false)

    const changeTheme = () => {
        setIsDarkTheme(!isDarkTheme)
    }

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            setIsDarkTheme(e.matches)
        })
    }, [])

    return (
        <>
            <ThemeProvider theme={isDarkTheme ? createTheme(dark) : createTheme(light)}>
                <CssBaseline />
                <ResponsiveDrawer
                    ToggleTheme={
                        <FormControlLabel
                            control={
                                <>
                                    <Switch checked={isDarkTheme} onChange={changeTheme} />
                                    <Brightness4TwoToneIcon />
                                </>
                            }
                            label=""
                            labelPlacement="start"
                        />
                    }
                >
                    <Box sx={{ pt: 10, pl: 3, pr: 3, pb: 10 }} id="back-to-top-anchor">
                        <Counter />
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route
                                path="/"
                                element={
                                    <>
                                        <GroupValori />
                                        <Ricerca />
                                        <TopTen gridColor={deepPurple[200]} />
                                    </>
                                }
                            />
                            <Route path="/:val.:anno_short.:progr" element={<Dettaglio />} />
                            <Route
                                path="/:val.:anno_short"
                                element={
                                    <>
                                        <GroupValori />
                                    </>
                                }
                            />
                            <Route
                                path="/:val"
                                element={
                                    <>
                                        <GroupValori />
                                    </>
                                }
                            />
                            <Route
                                path="/valori"
                                element={
                                    <PrivateRoute>
                                        <Valori type={TYPE_TABLE.VAL} gridColor={deepPurple[50]}>
                                            <Valori type={TYPE_TABLE.ANNO} gridColor={deepPurple[200]}>
                                                <Valori type={TYPE_TABLE.PROGR} gridColor={deepPurple[400]}>
                                                    <></>
                                                </Valori>
                                            </Valori>
                                        </Valori>
                                    </PrivateRoute>
                                }
                            />
                            <Route path="*" element={<p>404</p>} />
                        </Routes>
                    </Box>
                </ResponsiveDrawer>
                <Footer />
            </ThemeProvider>
        </>
    )
}

export default App
