import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone'
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone'
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone'
import MenuIcon from '@mui/icons-material/Menu'
import PaidTwoToneIcon from '@mui/icons-material/PaidTwoTone'
import { Fab, ListItemButton, useScrollTrigger, Zoom } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from './app/hooks'
import { useLogoutPostMutation } from './features/login/loginAPI'
import { selectState } from './features/login/loginSlice'
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone'

const drawerWidth = 240

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window
    children: React.ReactElement
    ToggleTheme?: React.ReactElement
}

function ScrollTop(props: Props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    })

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor')

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
    }

    return (
        <Zoom in={trigger}>
            <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                {children}
            </Box>
        </Zoom>
    )
}

export default function ResponsiveDrawer(props: Props) {
    const user = useAppSelector(selectState)

    const { window, children, ToggleTheme } = props
    const [mobileOpen, setMobileOpen] = React.useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    let navigate = useNavigate()

    const [
        updatePost, // This is the mutation trigger
        { isLoading, error }, // This is the destructured mutation result
    ] = useLogoutPostMutation()

    const handleClick = async () => {
        const rest = await updatePost().unwrap()
        navigate('/login')
    }

    const drawer = (
        <div>
            <List>
                <ListItem component={Link} to="/" button onClick={handleDrawerToggle}>
                    <ListItemIcon>{<HomeTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={'Home'} />
                </ListItem>
                {user.data.id != null && (
                    <ListItem component={Link} to="/valori" button onClick={handleDrawerToggle}>
                        <ListItemIcon>{<AccountTreeTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary={'Valori'} />
                    </ListItem>
                )}
            </List>
            <Divider />
            <List>
                {user.data.id == null ? (
                    <ListItem component={Link} to="/Login" button onClick={handleDrawerToggle}>
                        <ListItemIcon>{<LockTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary={'Login'} />
                    </ListItem>
                ) : (
                    <ListItemButton component="a" onClick={handleClick}>
                        <ListItemIcon>{<LockTwoToneIcon />}</ListItemIcon>
                        <ListItemText primary={user.data.email} />
                    </ListItemButton>
                )}
            </List>
            <Divider />
            <List>
                <ListItem target={'_blank'} component="a" href="https://www.erroridiconiazione.com">
                    <ListItemIcon>{<PaidTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={'Tecniche'} />
                </ListItem>
                <ListItem target={'_blank'} component="a" href="https://p.erroridiconiazione.com/it/0000/500/00226">
                    <ListItemIcon>{<FactCheckTwoToneIcon />}</ListItemIcon>
                    <ListItemText primary={'Perizie'} />
                </ListItem>
            </List>
        </div>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ width: '100%' }}>
                        Catalogo
                    </Typography>
                    {ToggleTheme}
                </Toolbar>
            </AppBar>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            {children}
            <ScrollTop>
                <Fab color="secondary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpTwoToneIcon />
                </Fab>
            </ScrollTop>
        </>
    )
}
