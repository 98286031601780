import {
    Avatar,
    Divider,
    IconButton,
    InputAdornment,
    InputBase,
    Paper,
    TextField,
    Theme,
    Typography,
} from '@mui/material'
import { DataGridPro, GridColumns, GridSortItem, GridSortModel } from '@mui/x-data-grid-pro'
import * as React from 'react'
import { usePostMutation } from './topTenAPI'
import { ValoriObj, ValoriProps } from './topTenSlice'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'

export function TopTen({ gridColor }: ValoriProps) {
    ;[...(document.querySelectorAll('div') as any)]
        .filter((el) => el.textContent.includes('Missing license key'))
        .reverse()[0]
        ?.remove()

    // PAGINATION

    const [queryOptions, setQueryOptions] = React.useState<{ sortModel: GridSortItem[] } | null>(null)

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] })
    }, [])

    const [addPost, { isLoading, isError }] = usePostMutation()

    const [rows, setRows] = React.useState<ValoriObj[] | undefined>()
    const [rowsCount, setRowsCount] = React.useState<number | undefined>()
    const [page, setPage] = React.useState(0)
    const [pageSize, setPageSize] = React.useState(10)
    const [loading, setLoading] = React.useState(isLoading)

    const [ricercaValue, setRicercaValue] = React.useState<string>('')

    React.useEffect(() => {
        const exect = async () => {
            const result = await addPost({
                gridSordModel: queryOptions?.sortModel,
                page,
                pageSize,
                ricercaValue,
            }).unwrap()
            setRows(result.data)
            setRowsCount(result.rowCount)
        }
        exect()
    }, [queryOptions, page, pageSize, ricercaValue])

    const columns: GridColumns = [
        {
            field: 'uuid',
            headerName: '',
            width: 180,
            editable: false,
            type: 'custom',
            hide: false,
            renderCell(data) {
                const { row } = data
                return row.uuid !== null ? (
                    <img
                        alt=""
                        src={`${process.env.REACT_APP_ENDPOINT}/immagini/by_uuid?uuid=${row.uuid}&width=50&height=50`}
                    ></img>
                ) : (
                    <Avatar variant="square" sx={{ marginRight: '2rem', width: '50' }}>
                        .
                    </Avatar>
                )
            },
        },
        { field: 'id', headerName: 'ID', width: 180, editable: false, type: 'string', hide: true },
        { field: 'val', headerName: 'Valore', width: 100, editable: false },
        { field: 'anno', headerName: 'Anno', width: 180, editable: true, hide: false },
        { field: 'progr', headerName: 'Progressivo', width: 180, editable: false, hide: false },
    ]

    // qnd finisce il caricamento rimette loading a false (caricamento terminato)
    React.useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    // DESIGN

    return (
        <Paper
            elevation={10}
            sx={{ pl: 3, pr: 3, pb: 2, pt: 3 }}
            style={{
                background: 'linear-gradient(0deg, rgba(255,160,0,0) 0%, rgba(101,69,0,0.04805672268907568) 100%)',
            }}
        >
            <Typography align="right" variant="h5">
                Ricerca libera
            </Typography>
            <TextField
                autoComplete="none"
                variant="filled"
                sx={{ mb: 2, mt: 3 }}
                fullWidth
                label="Digita qui il contenuto da ricercare - la ricerca è estesa anche ai dettagli non presenti in questa tabella"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchTwoToneIcon />
                        </InputAdornment>
                    ),
                }}
                value={ricercaValue}
                onChange={(input) => {
                    setRicercaValue(input.target.value)
                }}
            />
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGridPro
                        sx={(theme: Theme) => ({
                            //backgroundColor: gridColor,
                        })}
                        // DATI
                        loading={loading}
                        rows={rows || []}
                        columns={columns}
                        getRowId={(row) => row.id}
                        // PAGINAZIONE
                        rowCount={rowsCount}
                        pagination
                        page={page}
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 20, 30]}
                        paginationMode="server"
                        onPageChange={(newPage) => setPage(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        // ORDINAMENTO
                        onSortModelChange={handleSortModelChange}
                        disableMultipleColumnsSorting={true}
                        //getDetailPanelHeight={({ row }) => 600}
                        getRowHeight={(row) => 46}
                        headerHeight={36}
                        autoHeight={true}
                        experimentalFeatures={{ newEditingApi: false }}
                        onRowClick={(gridRow) => {
                            window.open(gridRow?.row?.link_action!, '_blank')
                        }}
                    />
                </div>
            </div>
        </Paper>
    )
}
