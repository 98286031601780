import { Avatar, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useSnackbar } from 'notistack'
import React, { forwardRef, SyntheticEvent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRicercaQuery } from './ricercaAPI'

export function Ricerca() {
    let navigate = useNavigate()

    const [page, setPage] = React.useState<number>(1)
    const [filter, setFilter] = React.useState<string>('valore')
    const { data, error, isFetching, isError } = useRicercaQuery({ page, filter })

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (error) {
            enqueueSnackbar(JSON.stringify(error))
        }
    }, [error])

    const handleScroll = (event: SyntheticEvent) => {}

    return (
        <Paper
            elevation={10}
            sx={{ pl: 3, pr: 3, pb: 2, pt: 3, mb: 4 }}
            style={{
                background: 'linear-gradient(0deg, rgba(255,160,0,0) 0%, rgba(101,69,0,0.04805672268907568) 100%)',
            }}
        >
            <Typography align="right" variant="h5">
                Ricerca per codice identificativo
            </Typography>
            <hr />
            <Autocomplete
                fullWidth
                groupBy={(data) => data.val}
                options={data?.data || []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(data) => data.val + '.' + data.anno_short + '.' + data.progr + ' (' + data.id + ')'}
                onChange={(event: any, newValue: any | null) => {
                    navigate(`/${newValue.val}.${newValue.anno_short}.${newValue.progr}`)
                }}
                renderOption={(props, data) => {
                    return (
                        <ListItem {...props}>
                            {data.uuid !== null ? (
                                <img
                                    alt=""
                                    src={`${process.env.REACT_APP_ENDPOINT}/immagini/by_uuid?uuid=${data.uuid}&width=50&height=50`}
                                ></img>
                            ) : (
                                <Avatar variant="square" sx={{ marginRight: '2rem', width: '50' }}>
                                    .
                                </Avatar>
                            )}
                            {data.val + '.' + data.anno_short + '.' + data.progr}
                        </ListItem>
                    )
                }}
                ListboxProps={{
                    onScroll: handleScroll,
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="per esempio -> valore.anno.progressivo"
                        margin="normal"
                        variant="outlined"
                        // onChange={(event) => {
                        //     console.log(event.target.value)
                        //     //setFilter(event.target.value)
                        // }}
                    />
                )}
            />
        </Paper>
    )
}

const ListboxComponent = forwardRef(function ListboxComponent({ setIsScrollBottom, ...rest }: any, ref: any) {
    return (
        <ul
            {...rest}
            onScroll={({ target }: any) => {
                console.log(target.scrollHeight - target.scrollTop === target.clientHeight)
                setIsScrollBottom(target.scrollHeight - target.scrollTop === target.clientHeight)
                if (target.scrollHeight - target.scrollTop === target.clientHeight) {
                    console.log(rest.children.length)
                    target.scroll(0, rest.children.length * 60)
                }
            }}
        />
    )
})
