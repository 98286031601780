import { createStyles, makeStyles, styled, Theme, Typography } from '@mui/material'

const StyledTypography = styled(Typography)`
   margins: {
    marginLeft: '1rem',
    wordBreak: 'break-word',
    width: '98%'
    }
`

function LabelDetail({ label, descrizione }: Props): JSX.Element {
    return (
        <>
            {descrizione != null && descrizione !== '' && (
                <>
                    <Typography variant="caption" color="textSecondary">
                        {label}
                    </Typography>

                    <StyledTypography variant="h6" color="textPrimary">
                        {descrizione}
                    </StyledTypography>
                </>
            )}
        </>
    )
}

export interface Props {
    label?: string | null
    descrizione?: string | null
}

export default LabelDetail
