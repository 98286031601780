import { Box, Link } from '@mui/material'
import React from 'react'

export const Footer = () => {
    return (
        <Box sx={{m:3}} textAlign="center">
            <Link
                component="button"
                variant="body2"
                onClick={() => {
                    window.open('http://www.ErroriDiConiazione.com', '_blank')
                }}
            >
                www.ErroriDiConiazione.com
            </Link>
        </Box>
    )
}
