import { Box, Container, createTheme, Grid, makeStyles, Theme, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCountQuery } from './counterAPI'
import CountUp from 'react-countup'

export function Counter() {
    let navigate = useNavigate()

    const { data, error, isFetching, isError } = useCountQuery()

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (error) {
            enqueueSnackbar(JSON.stringify(error))
        }
    }, [error])

    return (
        <div style={{ backgroundColor: 'primary.color.main' }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="baseline">
                <Typography align="right" variant="h6" style={{ marginRight: 15 }}>
                    Monete inserite
                </Typography>
                <Typography align="right" variant="h4" component="h5">
                    {data != null && data?.data.length > 0 && <CountUp duration={1} end={data!.data[0].count} />}
                </Typography>
            </Grid>
            <hr />
        </div>
    )
}
