import AddAPhotoTwoToneIcon from '@mui/icons-material/AddAPhotoTwoTone'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MobileStepper, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { useFilePicker } from 'use-file-picker'
import useWindowDimensions from '../../components/WindowDimesion'
import { snackActions } from '../../SnackbarUtilsConfigurator'
import { ValoriProps } from '../_valori/valoriSlice'
import { useDeletePostMutation, useImmaginiQuery, useInsertPostMutation } from './immaginiAPI'
import { Data } from './immaginiSlice'

export function ImmaginiEditable({ row, type }: ValoriProps) {
    const { id } = row!

    const { height, width } = useWindowDimensions()
    //const height = 200
    //const width = 200
    const { data, error, isFetching, isError, refetch } = useImmaginiQuery({ id })

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (error) {
            enqueueSnackbar(JSON.stringify(error))
        }
    }, [error])

    const [activeStep, setActiveStep] = React.useState<number>(0)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleStepChange = (index: number) => {
        setActiveStep(index)
    }

    const [insertImage, { isLoading: isLoadingInsert }] = useInsertPostMutation()

    const [openFileSelector, { filesContent, plainFiles, loading, clear }] = useFilePicker({
        readAs: 'DataURL',
        accept: ['image/*'],
        multiple: false,
    })

    useEffect(() => {
        async function asyncFn() {
            if (filesContent.length > 0 && isLoadingInsert === false && loading === false) {
                const image = filesContent[0].content
                //const image = await plainFiles[0].arrayBuffer()

                await insertImage({ id, image, type }).unwrap()
                setActiveStep((step) => data?.dataimagesval.length || 0)
            }
        }

        asyncFn()

        return () => {
            clear()
            refetch()
        }
    }, [filesContent.length])

    const [deleteImage, { isLoading }] = useDeletePostMutation()
    const [idDelete, setIdDelete] = React.useState<Data | null>(null)

    return (
        <Stack spacing={1} direction="column" justifyContent="center" alignItems="center">
            <Dialog maxWidth="xs" open={idDelete != null}>
                <DialogTitle>Confermi?</DialogTitle>
                <DialogContent dividers>{`Premi Si per confermare`}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setIdDelete(null)
                        }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={async () => {
                            try {
                                deleteImage(idDelete!)
                                setActiveStep((step) => (step > 0 ? step - 1 : 0))
                                snackActions.success('Data deleted')
                                setIdDelete(null)
                            } catch (error: any) {
                                console.log(error)
                            }
                        }}
                    >
                        Si
                    </Button>
                </DialogActions>
            </Dialog>
            <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
                <Button
                    variant="contained"
                    onClick={() => {
                        openFileSelector()
                    }}
                >
                    <AddAPhotoTwoToneIcon />
                </Button>
            </Stack>
            {data != undefined && data.dataimagesval.length > 0 && (
                <>
                    <SwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                        {data?.dataimagesval.map((row, index) => (
                            <div key={row.uuid}>
                                <Stack justifyContent="center" alignItems="center" padding={1}>
                                    <img
                                        src={`${process.env.REACT_APP_ENDPOINT}/immagini/by_uuid?uuid=${
                                            row.uuid
                                        }&width=${Math.floor(width / 5)}&height=${Math.floor(height / 4)}`}
                                        alt={row.uuid}
                                    />
                                </Stack>
                                <Stack justifyContent="center" alignItems="center" padding={1}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setIdDelete(row)
                                        }}
                                    >
                                        <DeleteForeverTwoToneIcon />
                                    </Button>
                                </Stack>
                            </div>
                        ))}
                    </SwipeableViews>
                    <MobileStepper
                        steps={data?.dataimagesval.length || 0}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === (data?.dataimagesval.length || 0) - 1}
                            >
                                {<KeyboardArrowRightIcon />}
                            </Button>
                        }
                        backButton={
                            <>
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {<KeyboardArrowLeftIcon />}
                                </Button>
                            </>
                        }
                    />
                </>
            )}
        </Stack>
    )
}
