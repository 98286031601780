import { useSnackbar } from 'notistack'
import React, { MouseEventHandler, useEffect } from 'react'
import useWindowDimensions from '../../components/WindowDimesion'
import { ValoriObj } from '../_valori/valoriSlice'
import { useImmaginiQuery } from './immaginiAPI'

export interface ValoriObjExt {
    valoriObj: ValoriObj
    onClick: MouseEventHandler<HTMLDivElement>
}

export function ImmaginiSingola(valoriObjExt: ValoriObjExt) {
    const { valoriObj, onClick } = valoriObjExt

    const { data, error, isFetching, isError } = useImmaginiQuery({ id: valoriObj?.id })

    if (data?.dataimagesval != null && data?.dataimagesval.length > 0) {
        const image = data?.dataimagesval[0]
        return (
            <img
                style={{ height: 50, width: 50, objectFit: 'contain', borderRadius: 10 }}
                src={`${process.env.REACT_APP_ENDPOINT}/immagini/by_uuid?uuid=${image.uuid}&width=${50}&height=${50}`}
                alt={image.uuid}
            />
        )
    }

    return <></>
}
