import CottageIcon from '@mui/icons-material/Cottage'
import { Breadcrumbs, Link, Paper } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'

export const Breadcrums = () => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    const arrParam = pathname.replace('/', '').split('.')

    let val: string | null = null
    let anno_short: string | null = null
    let progr: string | null = null

    switch (arrParam.length) {
        case 1:
            val = arrParam[0]
            break
        case 2:
            val = arrParam[0]
            anno_short = arrParam[1]
            break
        case 3:
            val = arrParam[0]
            anno_short = arrParam[1]
            progr = arrParam[2]
            break
        default:
            break
    }

    const handleClick = () => {
        //props.history.push()
    }

    const StyledLink = styled(Link)`
        textalign: center;
    `

    return (
        <Breadcrumbs
        separator="."
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginBottom: '1rem',
                justifyContent: 'center',
            }}
        >
            <StyledLink
                underline="none"
                variant="h6"
                onClick={() => {
                    navigate(`/`)
                }}
                aria-current="page"
            >
                <CottageIcon sx={{ fontSize: '2rem' }} />
            </StyledLink>

            {val != null && (
                <StyledLink
                    underline="none"
                    variant="h6"
                    onClick={() => {
                        navigate(`/${val}`)
                    }}
                    aria-current="page"
                >
                    {val}
                </StyledLink>
            )}
            {val != null && anno_short != null && (
                <StyledLink
                    underline="none"
                    variant="h6"
                    onClick={() => {
                        navigate(`/${val}.${anno_short}`)
                    }}
                    aria-current="page"
                >
                    {anno_short}
                </StyledLink>
            )}
            {val != null && anno_short != null && progr != null && (
                <StyledLink
                    underline="none"
                    variant="h6"
                    onClick={() => {
                        navigate(`/${val}.${anno_short}.${progr}`)
                    }}
                    aria-current="page"
                >
                    {progr}
                </StyledLink>
            )}
        </Breadcrumbs>
    )
}
