import { Card, CardContent, Link, Paper, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { SyntheticEvent, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LabelDetail from '../../components/LabelDetail'
import { Breadcrums } from '../breadcrumbs/Breadcrums'
import { Immagini } from '../immagini/Immagini'
import { useDettaglioQuery } from './dettaglioAPI'

export function Dettaglio() {
    const { pathname } = useLocation()

    const arrParam = pathname.replace('/', '').split('.')

    const { data, error, isFetching, isError } = useDettaglioQuery({
        val: arrParam[0],
        anno_short: arrParam[1],
        progr: arrParam[2],
    })

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (error) {
            enqueueSnackbar(JSON.stringify(error))
        }
    }, [error])

    return (
        <>
            <Breadcrums />
            {data?.data.length === 0 ? (
                <Typography>Nessun dettaglio trovato</Typography>
            ) : (
                data?.data.map((it) => {
                    return (
                        <div key={it.id} style={{ marginBottom: '1rem' }}>
                            <Immagini isDetail={true} valoriObj={it} onClick={() => {}} />
                            <Paper
                                elevation={10}
                                sx={{ pl: 3, pr: 3, pb: 2, pt: 3, mb: 4 }}
                                style={{
                                    background:
                                        'linear-gradient(0deg, rgba(255,160,0,0) 0%, rgba(101,69,0,0.04805672268907568) 100%)',
                                }}
                            >
                                <LabelDetail label="Progressivo" descrizione={it.val} />
                                <LabelDetail label="Descrizione errore" descrizione={it.descrizione_errore} />
                                <LabelDetail label="Tipo errore" descrizione={it.tipo_errore} />
                                <LabelDetail label="Peso" descrizione={it.peso} />
                                <br />
                                <LabelDetail label="Altre note" descrizione={it.altre_note} />
                                <br />
                                {it.link != null && it.link.length > 0 && (
                                    <>
                                        <Typography variant="caption" color="textSecondary">
                                            Link
                                        </Typography>
                                        <br></br>
                                        <Link target="_blank" href={it.link || ''}>
                                            {it.link}
                                        </Link>
                                    </>
                                )}
                            </Paper>
                        </div>
                    )
                })
            )}
        </>
    )
}
