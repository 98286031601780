import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { counterApi } from '../features/counter/counterAPI'
import counterReducer from '../features/counter/counterSlice'
import { immaginiApi } from '../features/immagini/immaginiAPI'
import immaginiReducer from '../features/immagini/immaginiSlice'
import { loginApi } from '../features/login/loginAPI'
import loginReducer from '../features/login/loginSlice'
import { ricercaApi } from '../features/ricerca/ricercaAPI'
import ricercaReducer from '../features/ricerca/ricercaSlice'
import { topTenApi } from '../features/topten/topTenAPI'
import topTenReducer from '../features/topten/topTenSlice'
import { dettaglioApi } from '../features/_dettaglio/dettaglioAPI'
import dettaglioReducer from '../features/_dettaglio/dettaglioSlice'
import { valoriApi } from '../features/_valori/valoriAPI'
import valoriReducer from '../features/_valori/valoriSlice'
import { rtkQueryErrorLogger } from './errorCatching'

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        login: loginReducer,
        ricerca: ricercaReducer,
        dettaglio: dettaglioReducer,
        immagini: immaginiReducer,
        valori: valoriReducer,
        topten: topTenReducer,
        [loginApi.reducerPath]: loginApi.reducer,
        [counterApi.reducerPath]: counterApi.reducer,
        [ricercaApi.reducerPath]: ricercaApi.reducer,
        [dettaglioApi.reducerPath]: dettaglioApi.reducer,
        [immaginiApi.reducerPath]: immaginiApi.reducer,
        [valoriApi.reducerPath]: valoriApi.reducer,
        [topTenApi.reducerPath]: topTenApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            rtkQueryErrorLogger,
            loginApi.middleware,
            counterApi.middleware,
            ricercaApi.middleware,
            dettaglioApi.middleware,
            immaginiApi.middleware,
            valoriApi.middleware,
            topTenApi.middleware,
        ),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
