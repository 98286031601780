import { Button, CardActions, MobileStepper, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { MouseEventHandler, SyntheticEvent, useEffect } from 'react'
import { useImmaginiQuery } from './immaginiAPI'
import SwipeableViews from 'react-swipeable-views'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import useWindowDimensions from '../../components/WindowDimesion'
import { ValoriObj } from '../_valori/valoriSlice'

export interface ValoriObjExt {
    valoriObj: ValoriObj
    onClick: MouseEventHandler<HTMLDivElement>
    isDetail?: Boolean
}

export function Immagini(valoriObjExt: ValoriObjExt) {
    const { valoriObj, onClick, isDetail } = valoriObjExt

    const { height, width } = useWindowDimensions()

    const { data, error, isFetching, isError } = useImmaginiQuery({ id: valoriObj?.id })

    const [activeStep, setActiveStep] = React.useState<number>(0)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleStepChange = (index: number) => {
        setActiveStep(index)
    }

    let _width = isDetail ? Math.floor(width) * 2 : Math.floor(width)
    let _height = isDetail ? Math.floor(height) * 2 : Math.floor(height)

    return (
        <>
            {data?.dataimagesval != null && data.dataimagesval.length > 0 && (
                <Stack spacing={1} direction="column" justifyContent="center" alignItems="center">
                    <SwipeableViews index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                        {data?.dataimagesval.map((row, index) => (
                            <div key={row.uuid} onClick={onClick}>
                                <img
                                    style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                                    src={`${process.env.REACT_APP_ENDPOINT}/immagini/by_uuid?uuid=${row.uuid}&width=${_width}&height=${_height}`}
                                    alt={row.uuid}
                                />
                            </div>
                        ))}
                    </SwipeableViews>
                    {data.dataimagesval.length > 1 && (
                        <CardActions>
                            <MobileStepper
                                steps={data?.dataimagesval.length || 0}
                                position="static"
                                variant="text"
                                activeStep={activeStep}
                                nextButton={
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={activeStep === (data?.dataimagesval.length || 0) - 1}
                                    >
                                        {<KeyboardArrowRightIcon />}
                                    </Button>
                                }
                                backButton={
                                    <>
                                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                            {<KeyboardArrowLeftIcon />}
                                        </Button>
                                    </>
                                }
                            />
                        </CardActions>
                    )}
                </Stack>
            )}
        </>
    )
}
