import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { valoriApi } from './valoriAPI'

export enum TYPE_TABLE {
    VAL = 'VAL',
    ANNO = 'ANNO',
    PROGR = 'PROGR',
}

export enum TYPE_FUNCTION {
    DETTAGLIO = 0,
    SEPARATORE = 1,
}

export interface ValoriPaginationRequest {
    gridSordModel?: GridSortModel
    gridFilterModel?: GridFilterModel
    page: number
    pageSize: number

    type: TYPE_TABLE

    row?: ValoriObj | undefined
}

export interface ValoriReorderRequest {
    oldIndex: number
    targetIndex: number
    rows: Array<ValoriObj>
}

export interface ValoriObj {
    isNew?: boolean

    id: number
    id_ref?: number
    type?: string
    val?: string
    val_short?: string
    val_ord?: number
    descrizione_errore?: string
    tipo_errore?: string
    peso?: string
    altre_note?: string
    link?: string

    link_action?: string
}

export interface ValoriObjInsertUpdate {
    newRow: ValoriObj
    oldRow: ValoriObj
    childRow?: ValoriObj
}

export interface ValoriReorder {
    id: number
    id_ref: number

    oldIndex: number
    targetIndex: number
}

export interface ValoriProps {
    children?: any
    row?: ValoriObj | undefined
    type: TYPE_TABLE
    gridColor?: string | undefined
}

export interface ValoriResponse {
    data: Array<ValoriObj>
    message?: string | null
    rowCount: number
}

const initialState = {
    [TYPE_TABLE.VAL]: {} as ValoriResponse,
    [TYPE_TABLE.ANNO]: {} as ValoriResponse,
    [TYPE_TABLE.PROGR]: {} as ValoriResponse,
}

// // The function below is called a thunk and allows us to perform async logic. It
// // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// // will call the thunk with the `dispatch` function as the first argument. Async
// // code can then be executed and other actions can be dispatched. Thunks are
// // typically used to make async requests.
// export const incrementAsync = createAsyncThunk('login/fetchCount', async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
// })

export const valoriSlice = createSlice({
    name: 'valori',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // increment: (state) => {
        //     // Redux Toolkit allows us to write "mutating" logic in reducers. It
        //     // doesn't actually mutate the state because it uses the Immer library,
        //     // which detects changes to a "draft state" and produces a brand new
        //     // immutable state based off those changes
        //     state.value += 1
        // },
        // decrement: (state) => {
        //     state.value -= 1
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload
        // },
    },
    // extraReducers: (builder) => {
    //     builder.addMatcher(valoriApi.endpoints.post.matchFulfilled, (state, { payload, type, meta }) => {
    //         console.log(payload)

    //         state[meta.arg.originalArgs.type] = payload
    //     })
    // },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading'
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle'
    //             state.value += action.payload
    //         })
    //         .addCase(incrementAsync.rejected, (state) => {
    //             state.status = 'failed'
    //         })
    // },
})

export const {} = valoriSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectState = (state: RootState) => state.valori

// // We can also write thunks by hand, which may contain both sync and async logic.
// // Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//     (amount: number): AppThunk =>
//     (dispatch, getState) => {
//         const currentValue = selectCount(getState())
//         if (currentValue % 2 === 1) {
//             dispatch(incrementByAmount(amount))
//         }
//     }

export default valoriSlice.reducer
